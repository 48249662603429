import React from "react"
import 'react-image-gallery/styles/css/image-gallery.css'
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import ImageGallery from 'react-image-gallery'
import Layout from "../components/layout"



import Seo from "../components/seo"
import { graphql, Link } from "gatsby"



const caseStudyDetail = ({data}) => {

    const  single_casestudy = data.wpCaseStudy;
    //console.log('case study detail',single_casestudy);

    const images_path = [];
    const rot_path = single_casestudy.caseStudy.caseStudyBanner
   
    rot_path.map( (single) =>  {
      images_path.push({original : single.bannerImage.localFile.childImageSharp.fluid.originalImg, originalAlt : single.bannerImage.altText})

    })

    //console.log('Image_path', images_path);


    return (
        <Layout>
          <Seo title={single_casestudy.title} />
<ImageGallery items={images_path}   showFullscreenButton = {false} autoPlay = {true} showNav = {false} showPlayButton = {false} showBullets = {true}  showThumbnails = {false}/>

<section className = "about_client">

<div className = "client_content"  dangerouslySetInnerHTML={{ __html: single_casestudy.caseStudy.aboutClientDetails.clientIntroduction}}>

    

</div>

<div className = "client_img">


<GatsbyImage
      image={getImage(single_casestudy.caseStudy.aboutClientDetails.projectImage.localFile)}
      quality = "90"
      alt = {single_casestudy.title} />

  


</div>



</section>




<section className = "services_client">

<div className="services_img">


<GatsbyImage
      image={getImage(single_casestudy.caseStudy.servicesToClient.serviceImage.localFile)}
      quality = "90"
      alt = {single_casestudy.title} />



</div>


<div className="services_details">

<h2>our services </h2>
<h2>to client</h2>

<div className="service_offer">

<ul>

{
      
      single_casestudy.caseStudy.servicesToClient.ourServices.map(service => {


return      <li>

<GatsbyImage
      image={getImage(service.serviceIcon.localFile)}
      quality = "90"
      alt ={service.serviceTitle} />

     <h4>{service.serviceTitle}</h4>

</li>





      })


}




</ul>


</div>



</div>

</section>
<section className = "complete_package">

<GatsbyImage
      image={getImage(single_casestudy.caseStudy.servicesToClient.projectFullImage.localFile)}
      quality = "90"
      alt ={single_casestudy.title} />


</section>




<section className = "service_types increased">


    <div className = "container">

      <div className = "serv_wrapper">


      



{ 

single_casestudy.caseStudy.ourGrowth.map(growth => {


return  <div className = "serv_box" >



<GatsbyImage
      image={getImage(growth.image.localFile)}
      quality = "90"
      alt ={growth.tile} />


    <div className ="serv_info">

      <h3>{growth.tile}</h3>
      <h1>{growth.percentage}</h1>
      <h4>{growth.details}</h4>

    </div>
  </div>




})

}


     

 




      
</div>
</div>
</section>



        </Layout>

       


    )

}


export const caseStudy_singlepost = graphql`

query SingleCaseStudy($slug: String) {
    wpCaseStudy(slug:{eq: $slug}) {
      id
      title
      caseStudy {
        projectType
        caseStudyBanner {
          bannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                fluid(quality: 100) {
                    originalImg
                  }
              }
            }
            altText
          }
        }
        aboutClientDetails {
          clientIntroduction
          projectImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
        servicesToClient {
          ourServices {
            serviceTitle
            serviceIcon {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, quality: 100)
                }
              }
            }
          }
          projectFullImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, quality: 100)
              }
            }
          }
          serviceImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, quality: 100)
              }
            }
          }


        }
        ourGrowth {
          tile
          percentage
          details
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
  

`


export default caseStudyDetail;